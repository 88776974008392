@import "../../_variables.scss";

.dashboard-container {
  background-color: $background-color;
  width: 100vw;
  height: 100vh;
  display: flex;

  .dashboard-sidenav {
    width: 280px;
    height: 100vh;
    background: #4a3c8c;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex-shrink: 0;

    .dashboard-sidenav-header {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 40px;
    }

    .dashboard-sidenav-body {
      width: 100%;
      padding: 20px;
      .sidenav-button {
        width: 100%;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        color: #b8b8b8;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: all 0.2s ease;

        &.active {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
      .sidenav-button:hover {
        font-weight: 600;
        color: #fff;
      }
    }

    .dashboard-sidenav-footer {
      width: 100%;
      padding: 20px;
      position: absolute;
      bottom: 20px;
      .sidenav-button {
        width: 100%;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        color: #b8b8b8;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: all 0.2s ease;

        &.active {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
      .sidenav-button:hover {
        font-weight: 600;
        color: #fff;
      }
    }
  }

  .dashboard-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
