@import "../../_variables.scss";

.assessment-page {
  background-color: $background-color;
  color: $text-primary;
  min-height: 100vh;
  position: relative;

  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.assessment-screen {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 30px;
  font-family: 'Inter', sans-serif;

  .assessment-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #ffffff;
  }

  .assessment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .assessment-meta {
      p {
        margin: 5px 0;
        font-size: 18px;
        
        span {
          font-weight: bold;
          color: $creative-purple;
        }
      }
    }

    .overall-score {
      h2 {
        font-size: 30px;
        color: $text-primary;

        .score {
          font-size: 36px;
          font-weight: bold;
          &.low { color: #ff4d4d; } // Red for low scores
          &.medium { color: #ffd700; } // Yellow for medium scores
          &.high { color: #4caf50; } // Green for high scores
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: $creative-purple;
  }

  .assessment-section {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    h2 {
      font-size: 28px;
      margin-bottom: 20px;
      color: $creative-purple;
    }

    .category-feedback {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;
      }

      h3 {
        font-size: 22px;
        margin-bottom: 15px;
        color: white; // Set the default text color to white

        .score {
          font-weight: bold;
          &.low { color: #ff4d4d; } // Red for low scores
          &.medium { color: #ffd700; } // Yellow for medium scores
          &.high { color: #4caf50; } // Green for high scores
        }
      }

      p {
        margin-bottom: 10px;

        strong {
          color: $creative-purple;
        }
      }
    }

    .strengths-and-improvements {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .strengths, .improvement-areas {
        width: 48%;
        min-width: 300px;
        margin-bottom: 30px;
      }

      ul {
        padding-left: 25px;

        li {
          margin-bottom: 15px;
          position: relative;

          &::before {
            content: '•';
            color: $creative-green;
            font-weight: bold;
            position: absolute;
            left: -20px;
          }
        }
      }
    }
  }

  .final-recommendation {
    p {
      font-style: italic;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  .category-scores {
    .recharts-wrapper {
      font-size: 12px;
    }

    .recharts-cartesian-grid-horizontal line,
    .recharts-cartesian-grid-vertical line {
      stroke: rgba(255, 255, 255, 0.1);
    }

    .recharts-text {
      fill: $text-primary;
    }

    .recharts-legend-item-text {
      color: $text-primary !important;
    }

    .recharts-tooltip-wrapper {
      background-color: $chart-background !important;
      border: 1px solid $creative-purple !important;
      border-radius: 4px;
    }

    .recharts-default-tooltip {
      background-color: transparent !important;
      border: none !important;
    }
  }
}

@media (max-width: 768px) {
  .assessment-screen {
    padding: 20px 10px;

    .assessment-header {
      flex-direction: column;
      align-items: flex-start;

      .overall-score {
        margin-top: 10px;
      }
    }

    .strengths-and-improvements {
      .strengths, .improvement-areas {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
