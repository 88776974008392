.assessment-v2-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;

  .back-button {
    // position: absolute;
    // top: 10px;
    // left: 0px;
    background: none;
    height: 40px;
    margin-top: 40px;
    margin-left: 10px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.2s;
    z-index: 10;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .assessment-v2-page-left {
    width: 70%;
    height: 100%;
    padding: 40px;
    overflow-y: auto;

    .assessment-content {
      max-width: 1000px;
      margin: 0 auto;

      .assessment-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.15);
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        .assessment-meta {
          p {
            margin: 5px 0;
            font-size: 18px;
            color: #fff;

            span {
              font-weight: bold;
              color: #673FD7;
            }
          }
        }

        .overall-score {
          h2 {
            font-size: 30px;
            color: #fff;

            .score {
              font-size: 36px;
              font-weight: bold;
              &.low { color: #ff4d4d; }
              &.medium { color: #ffd700; }
              &.high { color: #4caf50; }
            }

            .loading-message {
              display: inline-block;
              color: #ffd700;

              .refresh-hint {
                font-size: 0.9em;
                color: #a0aec0;
                margin-top: 0.5em;
              }
            }
          }
        }
      }

      .assessment-section {
        background-color: rgba(255, 255, 255, 0.05);
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: #fff;

        h2 {
          font-size: 24px;
          margin-bottom: 20px;
          color: #673FD7;
        }

        p {
          line-height: 1.6;
        }

        &.category-scores {
          .recharts-wrapper {
            font-size: 12px;
          }
        }

        &.strengths-and-improvements {
          .strengths, .improvement-areas {
            margin-bottom: 30px;

            ul {
              padding-left: 25px;

              li {
                margin-bottom: 15px;
                position: relative;

                &::before {
                  content: '•';
                  color: #13aa52;
                  font-weight: bold;
                  position: absolute;
                  left: -20px;
                }
              }
            }
          }
        }

        &.final-recommendation {
          p {
            font-style: italic;
            font-size: 18px;
          }
        }

        &.detailed-feedback {
          .category-feedback {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            &:last-child {
              border-bottom: none;
            }

            h3 {
              font-size: 22px;
              margin-bottom: 15px;
              color: white;

              .score {
                font-weight: bold;
                &.low { color: #ff4d4d; }
                &.medium { color: #ffd700; }
                &.high { color: #4caf50; }
              }
            }

            p {
              margin-bottom: 10px;

              strong {
                color: #673FD7;
              }
            }
          }
        }
      }
    }
  }
  .assessment-v2-page-right {
    width: 30%;
    height: 100%;
    border-left: 0.1px solid #e0e0e00f;
    .assessment-v2-page-right-top {
      height: 60%;
      border-bottom: 0.1px solid #e0e0e00f;
    }
    .assessment-v2-page-right-bottom {
      height: 40%;

      .transcript-header {
        padding: 15px;
        font-weight: 600;
        color: #fff;
        border-bottom: 0.1px solid #e0e0e00f;
      }

      .message-list {
        height: calc(100% - 60px);
        overflow-y: auto;
        padding: 10px;

        .message-block {
          margin: 20px;
          display: flex;
          height: auto;
          font-weight: 500;

          .message {
            display: flex;
            flex-direction: column;
            padding: 15px;
            border-radius: 10px;
            max-width: 700px;
            font-size: 14px;

            .message-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;

              .message-label {
                font-size: 12px;
                opacity: 0.8;
                font-weight: 600;
              }

              .message-timestamp {
                font-size: 11px;
                opacity: 0.6;
                margin-left: 8px;
              }
            }
          }
        }

        #incoming {
          justify-content: flex-start;
          .message {
            background-color: #673FD7;
          }
        }

        #outgoing {
          justify-content: flex-end;
          .message {
            background-color: #13aa52;
          }
        }
      }
    }
  }
}

.message-block.clickable {
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);

    .message {
      opacity: 0.9;
    }
  }
}

.message-block {
  &.current-time {
    border-left: 3px solid #673FD7;
    margin-left: 17px; // Compensate for the border

    .message {
      box-shadow: 0 0 10px rgba(103, 63, 215, 0.2);
    }
  }
}
