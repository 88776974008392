$background-color: #18181B;
$background-color-secondary: #1e1e1e;

$text-primary: white;
$text-secondary: rgb(181, 181, 181);
$creative-green: #13aa52;
$creative-purple: #673FD7;
$creative-purple-gradient: linear-gradient(
  92.88deg,
  #455eb5 9.16%,
  #5643cc 43.89%,
  #673fd7 64.72%
);
$destructive-red: #cb0000;

$score-low: #ff4d4d;
$score-medium: #ffd700;
$score-high: #4caf50;
$chart-bar-color: #8884d8;
$chart-background: #1e1e1e;

// Button Variables
$button-transition: transform 150ms, box-shadow 150ms;
$button-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
$button-hover-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;

// Plain Button
$plain-button-bg: transparent;
$plain-button-hover-bg: rgba(255, 255, 255, 0.1);
$plain-button-active-bg: rgba(255, 255, 255, 0.2);
$plain-button-color: white;

// Bold White Button
$bold-button-white-bg: white;
$bold-button-white-hover-bg: rgba(255, 255, 255, 0.9);
$bold-button-white-active-bg: rgba(255, 255, 255, 0.8);
$bold-button-white-color: black;

// Bold Black Button
$bold-button-black-bg: #000000;
$bold-button-black-hover-bg: #1a1a1a;
$bold-button-black-active-bg: #333333;
$bold-button-black-color: white;
