@import "../../_variables.scss";

.topnav {
  height: 120px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
  z-index: 1;
  
  .topnav-left {
    margin-left: 76px;
    #company-name {
      color: white;
      font-weight: 500;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .topnav-right {
    margin-right: 76px;
    display: flex;
    align-items: center;
    .topnav-name {
      color: black;
      font-size: 20px;
      margin: 0 2px;
    }
    #green-button {
      margin: 0px;
      margin-left: 25px;
    }
    .timer {
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
      #timer-icon {
        width: 22px;
      }
      #timer-text {
        font-weight: 500;
        font-size: 20px;
        margin-left: 5px;
        color: $text-primary;
      }
    }
  }
}
