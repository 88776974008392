@import "@fontsource/inter";
@import "@fontsource/inter/200.css";
@import "@fontsource/inter/300.css";
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";
@import "@fontsource/inter/600.css";
@import "./_variables.scss";
@import "./styles/buttons.scss";
.App {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  background-color: $background-color;
  .top-half-container {
    width: 100vw;
    height: 70vh;
    display: flex;
    .coderpad-container {
      width: 70%;
      height: 100%;
    }
    .ai-chat-container {
      width: 30%;
      height: 100%;
    }
  }
  .bottom-half-container {
    width: 100vw;
    height: 30vh;
  }

  #green-button {
    background-color: #13aa52;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 15px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 20px;
  }

  #green-button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
    transform: translateY(-2px);
  }

  #purple-button {
    background-color: $creative-purple;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 15px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 20px;
  }

  #purple-button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
    transform: translateY(-2px);
  }

  #input-label-text {
    font-size: 14px;
    color: white;
  }

  #input-box {
    height: 50px;
    background-color: $background-color-secondary;
    border-radius: 10px;
    width: auto;
    font-size: 20px;
    color: $text-primary;
    padding: 0 20px;
  }
}
