.signup-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .signup-gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../../src/assets/gradient-bg.jpg") no-repeat center center;
    background-size: cover;
    z-index: 1;
  }

  .signup-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;

    @media (max-width: 480px) {
      padding: 0;
      height: 100vh;
      max-width: none;
    }

    .signup-card {
      padding: 40px;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

      @media (max-width: 480px) {
        padding: 30px 20px;
        max-width: none;
        width: 100%;
        height: 100vh;
        border: none;
        border-radius: 0;
      }
    }
  }
} 