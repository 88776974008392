.small-screen-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    color: white;
    text-align: center;
    padding: 20px;
    overflow-x: hidden;
  
    .content {
        max-width: 90%;
        width: 100%;
        animation: fadeIn 0.5s ease-out;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 32px;
        transform: translateY(-20px);
        opacity: 0;
        animation: slideDown 0.5s ease-out forwards;

        h1 {
            font-weight: 700;
            font-size: 36px;
            background: linear-gradient(45deg, #673FD7, #9b6dff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0 2px 10px rgba(103, 63, 215, 0.3);
        }
    }

    .message {
        font-size: 24px;
        margin-bottom: 16px;
        color: #e0e0e0;
        opacity: 0;
        animation: fadeIn 0.5s ease-out 0.2s forwards;
    }

    .submessage {
        font-size: 18px;
        margin-bottom: 32px;
        color: #bdbdbd;
        opacity: 0;
        animation: fadeIn 0.5s ease-out 0.4s forwards;
    }

    .fun-message {
        background: rgba(103, 63, 215, 0.1);
        padding: 24px;
        border-radius: 12px;
        margin-bottom: 32px;
        border: 1px solid rgba(103, 63, 215, 0.2);
        box-shadow: 0 4px 20px rgba(103, 63, 215, 0.1);
        transform: translateY(20px);
        opacity: 0;
        animation: slideUp 0.5s ease-out 0.6s forwards;

        p {
            font-size: 20px;
            margin-bottom: 16px;
            color: #673FD7;
            font-weight: 600;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                font-size: 18px;
                margin-bottom: 16px;
                color: #e0e0e0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                transition: transform 0.2s;

                &:hover {
                    transform: translateX(5px);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .demo-text {
        font-size: 18px;
        margin-bottom: 24px;
        color: #bdbdbd;
        opacity: 0;
        animation: fadeIn 0.5s ease-out 0.8s forwards;
    }

    .video-container {
        position: relative;
        width: 100%;
        max-width: 560px;
        padding-bottom: 56.25%;
        margin: 0 auto;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        overflow: hidden;
        opacity: 0;
        animation: fadeIn 0.5s ease-out 1s forwards;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .small-screen-view {
        .header h1 {
            font-size: 28px;
        }

        .message {
            font-size: 20px;
        }

        .submessage {
            font-size: 16px;
        }

        .fun-message {
            padding: 20px;

            p {
                font-size: 18px;
            }

            ul li {
                font-size: 16px;
            }
        }

        .demo-text {
            font-size: 16px;
        }
    }
}