@import '../variables';

// Base button styles
%button-base {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: $button-transition;
  box-shadow: $button-shadow;
  border: none;
  outline: none;
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: $button-hover-shadow;
  }

  z-index: 100;
}

// Plain button
.plain-button {
  @extend %button-base;
  background-color: $plain-button-bg;
  color: $plain-button-color;
  font-size: 14px;
  
  &:hover {
    background-color: $plain-button-hover-bg;
  }
  
  &:active {
    background-color: $plain-button-active-bg;
  }
}

// Bold white button
.bold-button-white {
  @extend %button-base;
  background-color: $bold-button-white-bg;
  color: $bold-button-white-color;
  font-weight: 600;
  
  &:hover {
    background-color: $bold-button-white-hover-bg;
  }
  
  &:active {
    background-color: $bold-button-white-active-bg;
  }

}

// Bold black button
.bold-button-black {
  @extend %button-base;
  background-color: $bold-button-black-bg;
  color: $bold-button-black-color;
  font-weight: 600;
  border: 0.1px solid rgba(255, 255, 255, 0.5);
  
  &:hover {
    background-color: $bold-button-black-hover-bg;
  }
  
  &:active {
    background-color: $bold-button-black-active-bg;
  }
}

// Responsive styles
@media (max-width: 640px) {
  .plain-button {
    display: none;
  }
} 