.coding-timeline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  
  .editor-container {
    flex: 1;
    min-height: 0;
  }

  .timeline-controls {
    padding: 20px;
    background-color: #252526;
    border-top: 1px solid #333;

    .timeline-slider {
      width: 100%;
      height: 4px;
      -webkit-appearance: none;
      background: #444;
      border-radius: 2px;
      outline: none;
      
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #673FD7;
        cursor: pointer;
        transition: background .15s ease-in-out;
        
        &:hover {
          background: #5a36bc;
        }
      }
    }

    .timeline-labels {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      color: #888;
      font-size: 12px;
      font-family: monospace;
    }
  }
} 