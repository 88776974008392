.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;

  .landing-page-gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    @media (max-width: 700px) {
      height: 700px;
    }
    background: url("../../../src/assets/gradient-bg.jpg") no-repeat center
      center;
    background-size: cover;
    z-index: 1;
  }

  .landing-page-header-gradient {
    width: 100%;
    height: 200px;
    background-image: linear-gradient(
      to bottom,
      rgb(24, 24, 27),
      rgb(24, 24, 27) 30%,
      rgba(24, 24, 27, 0.9) 40%,
      rgba(24, 24, 27, 0.75) 50%,
      rgba(24, 24, 27, 0.05) 90%,
      transparent
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .landing-page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: background-color 0.2s ease;

    &.scrolled {
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 5;
    }

    .landing-page-nav {
      width: 100%;
      max-width: 1280px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      .landing-page-nav-left {
        display: flex;
        gap: 12px;
        align-items: center;
        #company-name {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .landing-page-nav-right {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }
  }

  .landing-page-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .landing-page-body-content-block-1 {
      width: 600px;
      height: 600px;
      margin-top: 200px;

      @media (max-width: 700px) {
        width: 100%;
        height: 400px;
        padding: 0 20px;
      }

      .tagline-1 {
        font-size: 72px;
        font-weight: 600;
        text-align: center;
        margin-bottom: -24px;
        .highlight-text {
          color: #4169e1;
          -webkit-text-stroke: 0.7px rgba(255, 255, 255, 1);
        }

        @media (max-width: 700px) {
          font-size: 36px;
          margin-bottom: -12px;
        }
      }

      .tagline-2 {
        font-size: 72px;
        font-weight: 600;
        text-align: center;

        .highlight-text {
          color: #f42b90;
          -webkit-text-stroke: 0.7px rgba(255, 255, 255, 1);
        }

        @media (max-width: 700px) {
          font-size: 36px;
        }
      }

      .description {
        font-size: 24px;
        text-align: center;
        font-weight: 400;

        @media (max-width: 700px) {
          display: none;
        }
      }

      .buttons {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        gap: 12px;
        @media (max-width: 700px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .video-container {
      margin-top: -100px;
      width: 900px;
      max-width: 95vw;
      z-index: 3;
      padding: 24px;
      background-color: rgb(17, 17, 17);
      border-radius: 16px;

      @media (min-width: 768px) {
        transform: translateY(-64px);
        padding: 64px;
        padding-bottom: 32px;
      }

      .video-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0 0 32px rgba(0, 170, 255, 0.467));
        video {
            height: auto;
            width: 100%;
        }
      }
    }

    .section-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: white;
  
      @media (max-width: 600px) {
        font-size: 28px;
        margin-bottom: 32px;
      }
    }

    .features-grid {
      width: 100%;
      max-width: 1200px;
      padding: 64px 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      .feature-card {
        background: rgba(24, 24, 27, 0.5);
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);

        .feature-image {
          width: 100%;
          height: 200px;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
        }

        #feature-image-1 {
          background-image: url("../../../src/assets/InterviewPreview.png");
        }

        #feature-image-2 {
          background-image: url("../../../src/assets/AssessmentPreview.png");
        }

        #feature-image-3 {
          background-image: url("../../../src/assets/ResumeGraphic.webp");
        }

        .feature-title {
          font-size: 24px;
          font-weight: 600;
        }

        .feature-description {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 1.5;
        }
      }
    }
  }
}

@keyframes glow {
  0%,
  100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
      0 0 30px rgba(255, 105, 180, 0.5), 0 0 40px rgba(65, 105, 225, 0.5);
  }
}

.blog-section {
  width: 100%;
  max-width: 1200px;
  padding: 64px 24px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 32px 16px;
  }

  .section-title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 48px;
    color: white;

    @media (max-width: 600px) {
      font-size: 28px;
      margin-bottom: 32px;
    }
  }

  .social-posts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    .social-post-card {
      background: rgba(24, 24, 27, 0.5);
      border-radius: 16px;
      padding: 24px;
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      flex-direction: column;
      transition: transform 0.2s ease;

      @media (max-width: 600px) {
        padding: 16px;
      }

      .post-platform {
        margin-bottom: 16px;

        .platform-icon {
          font-size: 24px;

          &.linkedin {
            color: #0077B5;
          }

          &.twitter {
            color: #1DA1F2;
          }

          &.medium {
            color: #ffffff;
          }
        }
      }

      .post-content {
        flex: 1;
        margin-bottom: 24px;

        .post-image {
          width: 100%;
          margin: 16px 0;
          border-radius: 12px;
          overflow: hidden;
          border: 1px solid rgba(255, 255, 255, 0.1);
          
          img {
            width: 100%;
            height: auto;
            display: block;
            transition: transform 0.3s ease;
          }
        }

        .post-date {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 8px;
        }

        .post-title {
          font-size: 20px;
          font-weight: 600;
          color: white;
          margin-bottom: 12px;
        }

        .post-excerpt {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 1.5;
          margin-bottom: 16px;
        }
      }

      .read-more {
        color: #673FD7;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.2s ease;
        padding-top: 16px;
        margin-top: auto;
        display: inline-block;

        &:hover {
          color: #5a36bc;
        }
      }
    }
  }
}
